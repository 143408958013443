@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideThumbnailsRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideThumbnailsLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.slider {
  position: relative;
  overflow: hidden;
}

.image-container {
  position: relative;
  width: 100%;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.animate-image-left {
  animation: slideInLeft 0.5s ease;
}

.animate-image-right {
  animation: slideInRight 0.5s ease;
}

.thumbnail-container {
  transition: transform 0.5s ease;
  white-space: nowrap;
}

.animate-thumbnail-left {
  animation: slideThumbnailsLeft 0.5s ease;
}

.animate-thumbnail-right {
  animation: slideThumbnailsRight 0.5s ease;
}

.thumbnail {
  transition: transform 0.3s ease;
  display: inline-block;
}

.thumbnail.active {
  transform: scale(1.1);
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.arrow-left {
  left: 10px;
}

.arrow-right {
  right: 10px;
}
