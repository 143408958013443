/* ./src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
body {
  margin: 0;
  box-sizing: border-box;
  animation: e;
}

* {
  box-sizing: border-box;
  /* background-color: rgb(251, 252, 252); */
}
