.img-container img {
  width: 400px; /* Increased width */
  height: 300px; /* Increased height */
  border-radius: 10px;
  object-fit: cover; /* Ensures consistent image display */
}

@media (max-width: 1200px) {
  .img-container img {
    width: 300px; /* Smaller size for tablets */
    height: 200px;
  }
}

@media (max-width: 768px) {
  .img-container img {
    width: 220px; /* Smaller size for tablets */
    height: 220px;
  }
}

@media (max-width: 480px) {
  .img-container img {
    width: 150px; /* Smaller size for mobile */
    height: 150px;
  }
}
