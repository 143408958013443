/* DialogContentStyles.css */

.dialog-content {
  background-color: #2a2a2a; /* Background color */
  max-height: 65vh;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.table {
  width: 100%;
  border-collapse: collapse;

}

.table th,
.table td {
  border: 1px solid #555;
  padding: 12px;
  text-align: left;
}

.table th {
  background-color: #3a3a3a; /* Header background */
  color: #e0e0e0; /* Header text color */
  font-weight: bold;
}

.table tr:nth-child(even) {
  background-color: #3a3a3a; /* Alternate row background */
}

.table tr:hover {
  background-color: #444; /* Hover effect */
}

.table-title {
    
  color: #e0e0e0;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 16px;
  padding-bottom: 8px;
}

.img-container {
  display: flex;
  gap: 10px;
  margin-top: 12px;
}

.img-container img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  transition: transform 0.2s, filter 0.2s;
  border: 2px solid #555; /* Border for images */
}

.img-container img:hover {
  transform: scale(1.1); /* Zoom effect on hover */
  filter: brightness(1.1); /* Brighten effect on hover */
}

.description-title {
  color: #e0e0e0;
  margin-top: 7px;
  font-size: 1.5em;
  border-bottom: 1px solid #555;
  padding-bottom: 4px;
}

.description-text {
  color: #b0b0b0;
  line-height: 1.6; /* Improved readability */
}

.no-items {
  margin: 1em 0;
  text-align: center;
  color: #e0e0e0;
  font-size: 1.2em;
}
